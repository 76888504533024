.main {
}

.modal {
  border-radius: 3px;
}

.header {
  font-family: "Editorial";
  font-weight: 500;
}

.closeIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border-radius: 100%;
  background-color: var(--primary-bg);
}

.body {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  border-radius: 3px;
}

.gglReviewTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  row-gap: 1rem;
  padding: 2rem;
  padding-bottom: 0rem;
}

.mainBody {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.listingRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.imgContainer {
  border-radius: 5px;
  object-fit: cover;
  width: 100px !important;
  height: 100px !important;
  position: relative;
}

.size {
  font-size: 16px;
  margin-bottom: 0rem;
  font-family: "Editorial";
}

.moreInfo {
  display: flex;
  flex-direction: column;
}

.section {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.img {
  width: inherit;
  object-fit: cover;
  height: inherit;
  border-radius: 9999px;
  border: 1px solid var(--primary-bg);
}

.imgProfile {
  width: inherit;
  object-fit: cover;
  height: inherit;
  border-radius: 5px;
  border: 1px solid var(--primary-bg);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 70px;
  height: 70px;
}
.buttons {
  display: flex;

  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}

.buttonRenew {
  border-radius: 3px;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  font-family: "Editorial";
  background-color: var(--text-main);
  color: white;
  padding: 10px 20px;
}

.reviewBox {
}

.topSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  padding-bottom: 0rem;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-image: linear-gradient(
    to bottom,
    var(--primary-purple) 0%,
    var(--primary-purple) 80%,
    transparent 80%,
    transparent 100%
  );

  align-items: center;
  row-gap: 1rem;

  width: 100%;
}

.textArea {
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  font-size: 14px;
}

.bottomSection {
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  padding-bottom: 2rem;
}

.rateSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.3rem;
}

.modalText {
  font-size: 14px;
  margin-bottom: 0rem;
  font-weight: 500;
  font-family: "Editorial";
}

.logo {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.modalText1 {
  font-size: 14px;
  margin-bottom: 0rem;
  font-weight: 500;
  font-family: "Editorial";
  width: 100%;
  text-align: left;
}

.gglReviewText {
  font-size: 14px;
  margin-bottom: 0rem;
  font-weight: 400;
  font-family: "Editorial";
  line-height: 180%;
}

.marginBottom {
  font-size: 18px;
  padding-bottom: 0.5rem;
}

.reviewText {
  color: white;
  text-align: center;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 0rem;
}

.font {
  font-family: "Editorial";
}

.helpText {
  font-weight: 400;
  color: var(--text-body);
  font-size: 14px;
}

.buttonRenew:hover {
  opacity: 0.8;
  color: white;
}

.button {
  border-radius: 3px;
  display: flex;
  padding: 10px 20px;

  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: var(--primary-bg);
  width: 100%;

  font-family: "Editorial";
  color: #222222;
  cursor: pointer;
}

.button:hover {
  opacity: 0.6;
  color: #222222;
}

.listingDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
}

.spaceTop {
  margin-top: 0.5rem;
}

.listingStatus {
  background-color: var(--primary-blue-400);
  color: white;
  width: fit-content;
  max-width: none;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 14px;
  border-radius: 5px;
  text-transform: capitalize;
}

.listingTitle {
  font-size: 14px;
}

.listingPrice {
  font-weight: 500;
  font-size: 14px;
}

.actionsButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.actionButton {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.sectionBody {
  display: flex;
  flex-direction: column;
}

.sectionSection {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}

.sectionText {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0rem;
}

.muteText {
  font-size: 13px;
  color: #65676b;
  font-weight: 300;
  margin-bottom: 0rem;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-bg);
  border-radius: 100%;
  height: 48px !important;
  width: 48px !important;
}

.iconContainer1 {
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 100%;
  height: 48px !important;
  min-width: 48px;
  width: 48px !important;
  margin-right: 0.75rem;
}

.icon {
  width: 60%;
  height: 60%;
}

.actionText {
  margin-top: 0.5rem;

  font-size: 15px;
  margin-bottom: 0rem;
  font-weight: 500;
}

.iconContainer:hover {
  background-color: #c9cfd4;
}

.showButton {
  display: flex;
  margin-left: calc(48px + 0.5rem);
  justify-content: center;
  align-items: center;
  background-color: var(--primary-purple);
  margin-top: 1rem;
  width: fit-content;
  color: white;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
}

.noneAvailable {
  font-size: 13px;
  margin-top: 1rem;
}

.leftSection {
  width: 50%;
  border-right: 1px solid var(--primary-bg);
}
.rightSection {
  width: 50%;
  padding: 0.2rem 1rem;
}

.topRightSection {
  display: flex;
  flex-direction: row;
}

.selection {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 15px;
  font-weight: 500;
}

.activeSelection {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 15px;
  font-weight: 500;
  color: var(--primary-blue-400);
  border-bottom: 2px solid var(--primary-blue-400);
}

.selection:hover {
  border-radius: 5px;

  background-color: var(--primary-bg);
}
.space {
  margin-right: 0.2rem;
}

.sectionTitle {
  font-size: 20px;
  font-weight: 500;
  margin-top: 1rem;
}

@media (max-width: 600px) {
  .mainBody {
    flex-direction: column;
  }
  .leftSection {
    border-right: none;
    width: 100%;
  }

  .rightSection {
    border-top: 1px solid var(--primary-bg);
    width: 100%;
    padding-bottom: 1rem;
  }
  .modal {
    width: 100%;
  }
}

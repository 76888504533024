.container {
  padding: 2em;
  text-align: center;
  min-width: 15em;
  margin: auto;
  width: 50%;
}

.header {
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.img {
  max-height: 5em;
  max-width: 5em;
  border-radius: 4px;
}

.phoneIcon {
  height: 3em;
  width: 3em;
  margin: auto;
  width: 100%;
  margin-bottom: 1em;
}

.sendCodeButton {
  /* position: absolute;
	bottom: 0;
	right: 0; */
  background-color: var(--primary-purple);
  padding: 0.5em;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  margin: auto;
  width: 100%;
  border: 0;
}

.sendCodeButton:hover {
  background-color: #519451;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1001;
  height: 100vh;
}

.modal {
  position: fixed;
  top: 30%;
  left: 50%;
  margin: auto;
  width: 50%;
  border-radius: 17px;
  transform: translate(-50%, -30%);
  background-color: #fff;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  z-index: 1002;
}

.modal2 {
  position: fixed;
  top: 40%;
  left: 50%;
  margin: auto;
  width: 40%;
  border-radius: 17px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 40px;
  z-index: 1000;
}

.error {
  color: var(--primary-red-400);
  width: 100%;
  text-align: center;
}

.goBack {
  margin-top: 0.5em;
  color: rgba(0, 0, 0, 0.404);
  font-style: italic;
}

.goBack:hover {
  cursor: pointer;
}

.logout:hover {
  cursor: pointer;
}

.logout {
  width: 100%;
  text-align: center;
}

.closeCross {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
  height: 1.5em;
  width: 1.5em;
}

.container2 {
  margin: 5%;
}

.description {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.submitButton {
  width: 100%;
  background-color: var(--primary-purple);
  color: white;
  border-radius: 7px;
  text-align: center;
  font-weight: 600;
  padding: 0.5em;
  cursor: pointer;
}

@media (max-width: 600px) {
  .modal {
    width: 95%;
  }
  .modal2 {
    width: 95%;
  }

  .container {
    width: 95%;
  }
}
